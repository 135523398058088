export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBxhqtEziy6FTj-QhWhr1PEp6B_HJIw8KM",
    authDomain: "playweb-stat-huayvip.firebaseapp.com",
    projectId: "playweb-stat-huayvip",
    storageBucket: "playweb-stat-huayvip.appspot.com",
    messagingSenderId: "633668178196",
    appId: "1:633668178196:web:a1ba6c3a9476c381c43201"
  }
};

<!--fixed-top-->
<nav class="navbar navbar-expand-md bg-dark navbar-dark ">
    <a class="navbar-brand" href="#">Angular Bootstrap Demo</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
  
        <li class="nav-item">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/schedule">กำหนดเอง</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact-create">สรุปภาพรวม</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/addstock">เพิ่มข้อมูลหวยหุ้น</a>
        </li>
      </ul>
    </div>
  </nav>
  
  <!-- <div class="pos-f-t">
    <div class="collapse" id="navbarToggleExternalContent">
      <div class="bg-dark p-4">
        <h4 class="text-white">Collapsed content</h4>
        <span class="text-muted">Toggleable via the navbar brand.</span>
      </div>
    </div>
    <nav class="navbar navbar-dark bg-dark">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </nav>
  </div> -->
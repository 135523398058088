<p>all works!</p>
<!-- <div class="chartjs-container">
    <canvas baseChart 
        [datasets]="lineChartData" 
        [labels]="lineChartLabels" 
        [options]="lineChartOptions"
        [colors]="lineChartColors" 
        [legend]="lineChartLegend" 
        [chartType]="lineChartType" 
        [plugins]="lineChartPlugins">
    </canvas>
</div> -->
<!-- <canvas #myAreaChart></canvas> -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">Area Chart Example</div>
            <div class="card-body">
                <canvas #myAreaChart></canvas>
            </div>
        </div>
    </div>
    <div class="col-xl-6">
        <!-- <sb-card> -->
            <!-- <div class="card-header"><fa-icon class="mr-1" [icon]='["fas", "chart-bar"]'></fa-icon>Bar Chart Example</div> -->
            <!-- <div class="card-body"><sb-charts-bar></sb-charts-bar></div> -->
        <!-- </sb-card> -->
    </div>
</div>

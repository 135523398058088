
    <div class="jumbotron" style="background-color: #fff; height: calc(95vh);">
        <div class="ibox ">
            <div class="ibox-content">
                <div class="ibox-subtitle">
                    <h4>Domain & Host</h4>
                </div>
                <div class="row">
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="domainHost" name="domainHost"
                            placeholder="Domain & Host" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="directadmin" name="directadmin"
                            placeholder="Direct Admin" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="userDirectadmin" name="userDirectadmin"
                            placeholder="User DirectAdmin" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="passDirectadmin" name="passDirectadmin"
                            placeholder="Pass DirectAdmin" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <br>
                <div class="ibox-subtitle">
                    <h4>FTP</h4>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-md-12 col-sm-12 col-12">
                        <input class="form-control" [(ngModel)]="hostFTP" name="hostFTP"
                            placeholder="host ip address" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="userFTP" name="userFTP"
                            placeholder="User FTP" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="passFTP" name="passFTP"
                            placeholder="Pass FTP" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>

                <br>
                <div class="ibox-subtitle">
                    <h4>WordPress</h4>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="userWP" name="userWP"
                            placeholder="User WP" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="passWP" name="passWP"
                            placeholder="Pass WP" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <br>
                <div class="ibox-subtitle">
                    <h4>ผู้ให้บริการ</h4>
                </div>
                <div class="row">
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="userRegis" name="userRegis"
                            placeholder="User Login" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="passRegis" name="passRegis"
                            placeholder="Pass Login" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="expriredRegis" name="expriredRegis"
                            placeholder="วันที่ตรวจสอบ" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="hostRegis" name="hostRegis"
                            placeholder="ผู้ให้บริการ" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <br>
                <div class="ibox-subtitle">
                    <h4>Cloudflare</h4>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6">
                        <input class="form-control"  [(ngModel)]="emailCloudflare" name="emailCloudflare"
                            placeholder="Email Cloudflare" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-6">
                        <input class="form-control" name="passCloudflare" [(ngModel)]="passCloudflare" 
                            placeholder="Pass Cloudflare" >
                    </div>
                </div>
                <br>
                <div class="ibox-subtitle">
                    <h4>Note</h4>
                </div>
                <div class="row">
                    <div class="col-12">
                        <textarea row='5' class="form-control"  [(ngModel)]="noteDomain" name="noteDomain"
                        placeholder="Note มีอะไรจะเพิ่มเติมบอกมาได้เลย" ></textarea>
                    </div>
                </div>


                <!-- <br>
                <div class="ibox-subtitle">
                    <h4>FTP</h4>
                </div>
                <div class="row">
                    
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        x
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        x
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        x
                    </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-6">
                        x
                    </div>
                </div> -->
                <br>
                <br>
                <button mat-raised-button style="width: 100%;" (click)="onAddDomain()"> Add Domain&Host </button>
                <div id="result"></div>
            </div>

        </div>
    </div>
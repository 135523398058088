import { LoginService } from './../services/login.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  progressSpinner: any;
  userlogin = '';
  password = '';
  loginStatus = false;

  constructor(private loginService: LoginService,
    private router: Router,
    private afAuth: AngularFireAuth,) { }

  ngOnInit(): void {
    this.router.navigateByUrl('/profile')

    // this.afAuth.onAuthStateChanged(user => {
    //   console.log('appComponent : ', user);
    //   if (user == null){
    //     // this.router.navigateByUrl('/login')
    //   }else{
    //     // this.router.navigateByUrl('/profile')
    //   }
    // })
  }

  onForgot(){
    console.log('onForgot');
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'โปรดติดต่อคนที่หล่อที่สุดในทีม',
      // footer: '<a href>Why do I have this issue?</a>'
    })
  }

  onLogin(){
    Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'โปรดติดต่อคนที่หล่อที่สุดในทีม',
        })
    // this.progressSpinner = true;
    // if (this.userlogin != null && this.userlogin != '' && this.password != null && this.password != '') {
    //   this.progressSpinner = false;
    //   this.loginService.login(this.userlogin + '@' + this.userlogin + '.com', this.password);
    // }else{
    //   // console.log(this.userlogin + '@' + this.userlogin + '.com', this.password);
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'กรอกข้อมูลไม่ครบ',
    //   })
    // }

    // let result = await this.loginService.getUserlogin(this.userlogin, this.password);
    // console.log('onLogin', result);
    // if(result){
    //   this.router.navigate(['/']);
    // }else{
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'กรอกผิด กรอกใหม่จร้า',
    //     // footer: '<a href>Why do I have this issue?</a>'
    //   })
    // }

  }

}

import { Component, OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  loginStatus: any;

  constructor(private afAuth: AngularFireAuth,
    private router: Router,) { }

    ngOnInit(): void {
    this.afAuth.onAuthStateChanged(user => {
      // console.log('appComponent : ', user);
      if (user == null){
        this.loginStatus = true;
        // this.location.back()
        // this.router.navigate(['/login'], { replaceUrl: true })
        // this.router.navigate(['/login']);
      }else{
        this.loginStatus = true;
        console.log(this.loginStatus);
      }
    })
  }


}

<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<div class="container mt-5 mb-5">
	<div class="row">
		<div class="col-md-6 offset-md-3">
			<!-- <h4>Latest News</h4> -->
			<ul class="timeline">
				<!-- <li>
					<a target="_blank" href="https://www.totoprayogo.com/#">New Web Design</a>
					<a href="#" class="float-right">21 March, 2014</a>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare. Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula....</p>
				</li>
				<li>
					<a href="#">21 000 Job Seekers</a>
					<a href="#" class="float-right">4 March, 2014</a>
					<p>Curabitur purus sem, malesuada eu luctus eget, suscipit sed turpis. Nam pellentesque felis vitae justo accumsan, sed semper nisi sollicitudin...</p>
				</li> -->
				<li>
					<div class="row">
						<div class="col-sm-6">
							<input type="text" class="form-control" 
                			[(ngModel)]="holder" [ngModelOptions]="{standalone: true}" placeholder="ผู้ถือครอง">
						</div>
						<div class="col-sm-6">
							<input type="text" class="form-control" 
                			[(ngModel)]="place" [ngModelOptions]="{standalone: true}" placeholder="สถานที่">
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<input type="text" class="form-control" 
							[(ngModel)]="detail" [ngModelOptions]="{standalone: true}" placeholder="รายละเอียด">
						</div>
					</div>
					<button mat-raised-button style="width: 100%;" (click)="onAddTimeline()"> Add TimeLine </button>
				</li>
			</ul>
			<h4>TimeLine : {{itemsName}}</h4>
			<ul class="timeline" >
				<li *ngFor="let timeline of dataTimeline">
					<a href="#">{{timeline.timelineHolder}}</a>
					<!-- <a href="#" class="float-right">{{timeline.dateCreate | date : 'yyyy-MM-dd HH:mm a'}} น.</a> -->
					<a href="#" class="float-right">{{timeline.dateCreate | date : "fullDate"}}</a>
					<p>สถานที่ : {{timeline.timelinePlace}}</p>
					<p>{{timeline.timelineDetail}}</p>
				</li>
			</ul>
			<br>
			<button mat-raised-button style="width: 100%;" (click)="onDisable()"> Disable Item </button>
		</div>
	</div>
</div>

<!-- <div class="text-muted mt-5 mb-5 text-center small">by : <a class="text-muted" target="_blank" href="http://totoprayogo.com">totoprayogo.com</a></div> -->

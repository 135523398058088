import { LoginService } from './../services/login.service';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  menuStatus = false;
  dataUser: any;
  userType: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private afAuth: AngularFireAuth,
    private router: Router,
    private loginService: LoginService) {}

  async ngOnInit(){
    this.menuStatus = true;
    this.userType = "admin";
    // this.afAuth.onAuthStateChanged(async user => {
    //   // console.log('appComponent : ', user);
    //   if (user == null){
    //     this.menuStatus = false;
    //     // this.loginStatus = true;
    //     // this.router.navigateByUrl('/login')
    //     // this.router.navigate(['/login'])
    //   }else{
    //     this.menuStatus = true;
    //     this.dataUser = await this.loginService.getDatauser(user.uid);
    //     this.userType = this.dataUser.type;
    //     console.log('dataUser : ', this.dataUser);
    //     if(this.dataUser.type == 'it'){
          
    //     }
    //     // this.router.navigate(['/profile'])
    //   }
    // })
  }

  onLogout(){
    this.afAuth.signOut().then(()=>{
      this.router.navigateByUrl('/login')
      window.location.reload();
    })
  }

}

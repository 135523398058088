import { StockService } from './../../services/stock.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {

  searchitems: any;
  userData: any;
  loginStatus: any;

  itemsData: any;
  tmp: any;

  constructor(private http: HttpClient,
    private afAuth: AngularFireAuth,
    private router: Router,
    private StockService: StockService,) { }

  ngOnInit() {
    this.afAuth.onAuthStateChanged(async user => {
      // console.log('appComponent : ', user);
      if (user == null){
        this.loginStatus = true;
        // this.location.back()
        // this.router.navigate(['/login'])
      }else{
        this.loginStatus = true;
        console.log(this.loginStatus);

        this.tmp = await this.StockService.getItemsData('enable');
        console.log('itemsData : ', this.tmp);
        // this.tmp = this.itemsData;
        
      }
    })
  }

  onTimeline(items){
    this.router.navigate(['/stock/timeline/' + items.key], {
      state: {
        itemsName: items.itemsName,
      }
    });

    // window.open('/stock/timeline/' + items.key, "_blank");
  }

  initializeItems(){
    this.itemsData = this.tmp;
  }

  searchContent(ev: any) {
    // Reset items back to all of the items
    // console.log(ev);
    
    this.initializeItems();

    // set val to the value of the searchbar
    // let val = ev.target.value;
    let val = ev;
    // console.log(val);
    
    //this.search = val;

    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      this.itemsData = this.itemsData.filter((item) => {
        // return (item.itemsSearch.toLocaleLowerCase().includes(val));
        return (item.itemsSearch.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }else{
      this.itemsData = [];
    }
    
  }

}

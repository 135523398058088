<nav class="navbar navbar-expand-md bg-dark navbar-dark " >
  <a class="navbar-brand" href="#">893 Team</a>
  <button text-right type="button" aria-label="Toggle sidenav" mat-icon-button 
  (click)="drawer.toggle()" >
    <mat-icon aria-label="Side nav toggle icon" class="white-icon">menu</mat-icon>
  </button>
</nav>
<mat-sidenav-container class="sidenav-container" >
  <mat-sidenav #drawer class="sidenav gray-color" mode="side"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list *ngIf="menuStatus">
      <div *ngIf="userType == 'it'">
        <div mat-subheader>Domain</div>
        <mat-list-item [routerLink]="['/domain']" [routerLinkActive]="['active']">
          <mat-icon mat-list-icon>people_alt</mat-icon>
          <div mat-line>โดเมน&โฮส</div>
        </mat-list-item>
      </div>
      
      <div *ngIf="userType == 'admin'">
        <div mat-subheader>Check User</div>
        <mat-list-item [routerLink]="['/checkuser-excel']" [routerLinkActive]="['active']">
          <mat-icon mat-list-icon>verified</mat-icon>
          <div mat-line>อัพโหลดไฟล์</div>
        </mat-list-item>

        <!-- <div mat-subheader>Huay Stock</div>
        <mat-list-item [routerLink]="['/dashboards/all']" [routerLinkActive]="['active']">
          <mat-icon mat-list-icon>auto_graph</mat-icon>
          <div mat-line>DashBoard</div>
        </mat-list-item> -->

        <!-- <div mat-subheader>การจัดการ</div>
        <mat-list-item>
          <mat-icon mat-list-icon>people_alt</mat-icon>
          <div mat-line>การจัดการพัสดุ</div>
        </mat-list-item>
          <mat-list style="margin-left:30px;">
              <div >
                <mat-list-item [routerLink]="['/stock/items']" [routerLinkActive]="['active']">
                  <div mat-line>รายการพัสดุ</div>
                </mat-list-item>
                <mat-list-item [routerLink]="['/stock/additem']" [routerLinkActive]="['active']">
                  <div mat-line>เพิ่มพัสดุ</div>
                </mat-list-item>
              </div>
          </mat-list>
        <mat-list-item [routerLink]="['/accounts/register']" [routerLinkActive]="['active']">
          <mat-icon mat-list-icon>people_alt</mat-icon>
          <div mat-line>สร้างยูสเซอร์ใหม่</div>
        </mat-list-item>
        <mat-list-item [routerLink]="['/accounts/list']" [routerLinkActive]="['active']">
          <mat-icon mat-list-icon>people_alt</mat-icon>
          <div mat-line>รายชื่อพนักงาน</div>
        </mat-list-item>
        <mat-list-item [routerLink]="['/domain']" [routerLinkActive]="['active']">
          <mat-icon mat-list-icon>people_alt</mat-icon>
          <div mat-line>โดเมน&โฮส</div>
        </mat-list-item>-->
      </div> 

      <div mat-subheader>ตั้งค่า</div>
      <mat-list-item [routerLink]="['/profile']" [routerLinkActive]="['active']">
        <mat-icon mat-list-icon>people_alt</mat-icon>
        <div mat-line>Profile</div>
      </mat-list-item>
      <!-- <mat-list-item (click)="onLogout()">
        <mat-icon mat-list-icon>people_alt</mat-icon>
        <div mat-line>Logout</div>
      </mat-list-item> -->

    </mat-nav-list>
  </mat-sidenav>

  <!-- <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>example</span>
    </mat-toolbar>
    Add Content Here
  </mat-sidenav-content> -->
  <router-outlet ></router-outlet>
</mat-sidenav-container>

<!-- <router-outlet *ngIf="menuStatus"></router-outlet> -->
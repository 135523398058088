import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  contacts = [
    {id: 1, name: "Contact 001", description: "Contact 001 des", email: "c001@email.com"},
    {id: 2, name: "Contact 002", description: "Contact 002 des", email: "c002@email.com"},
    {id: 3, name: "Contact 003", description: "Contact 003 des", email: "c003@email.com"},
    {id: 4, name: "Contact 004", description: "Contact 004 des", email: "c004@email.com"}
  ];

  constructor(private afdb: AngularFireDatabase) { }

  public getContacts():Array<{id, name, description, email}>{
    return this.contacts;
  }

  public createContact(contact: {id, name, description, email}){
    this.contacts.push(contact);
  }
  
  getDownjoneThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/usa/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  getNikkei01ThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/jp01/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  getChina01ThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/cn01/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  getHangSeng01ThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/hk01/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  getTaiwanThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/tw/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  getKoreaThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/kr/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }
  
  getNikkei02ThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/jp02/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  getChina02ThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/cn02/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  getHangSeng02ThisMonth(years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/hk02/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  getStockThisMonth(stock, years, month): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/' + stock + '/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  // getKoreaThisMonth(years, month): Promise<any> {
  //   return new Promise(resolve => {
  //     this.afdb.list('/huay/stock/kr/' + years + '/' + month).snapshotChanges().subscribe((data: any)=>{
  //       resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
  //     });
  //   })
  // }

  getDataStockToday(sid, today): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/stock/' + sid , ref => ref.orderByChild('dateTime').equalTo(today)).snapshotChanges().subscribe((data: any)=>{
        resolve(data);
      });
    })
  }

  addDataStock(sid, dateTime: string, timeStamp, dayWord, value){
    // console.log(dateTime);
    this.afdb.list('/huay/stock/' + sid).push({
      dateTime: dateTime,
      timeStamp,
      dayWord,
      value
    });
  }

  updateDataStock(sid, dateTime: string, timeStamp, dayWord, value, key){
    // console.log(dateTime);
    this.afdb.object('/huay/stock/' + sid + '/' + key ).update({
      dateTime: dateTime,
      timeStamp: timeStamp,
      dayWord: dayWord,
      value: value
    });
  }

  addDataStock2(sid, y, m, d, value){
    this.afdb.object('/huay/stock/' + sid + '/' + y + '/' + m + '/' + d).update({value});
    // this.afdb.list('/huay/stock/' + sid + '/' + y + '/' + m + '/' + d).push({value});
  }

  updateDataStock2(sid, y, m, d, value){
    this.afdb.object('/huay/stock/' + sid + '/' + y + '/' + m + '/' + d).update({value});
    // this.afdb.list('/huay/stock/' + sid + '/' + y + '/' + m + '/' + d).push({value});
  }

  getDatauserList(): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/huay/account/').snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
        // resolve(data);
      });
    })
  }

}

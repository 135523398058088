<div *ngIf="loginStatus">
<div class="jumbotron" style="background-color: #fff; height: calc(95vh);">
    <div class="ibox ">
        <div class="ibox-title">
            <h5>เลือกกรอก้อมูลเพื่อทำการค้นหเพียงช่องเดียว</h5>
        </div>
        <div class="ibox-content">
            <form id="customer_search_form">
                <div class="form-group row"><label class="col-sm-2 col-form-label">Username</label>
                    <div class="col-sm-10">
                        <input class="form-control"  [(ngModel)]="userlogin" [ngModelOptions]="{standalone: true}">
                        <!-- <input type="text" class="form-control" [(ngModel)]="user.login" > -->
                    </div>
                </div>
                <div class="form-group row"><label class="col-sm-2 col-form-label">เบอร์โทร</label>
                    <div class="col-sm-10">
                        <input class="form-control" [(ngModel)]="usertel" [ngModelOptions]="{standalone: true}">
                        <!-- <input type="text" class="form-control" [(ngModel)]="user.tel" > -->
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-4 col-sm-offset-2"  >
                        <button class="btn btn-primary btn-sm" (click)="onSearchUser()" 
                        type="button" id="search">ค้นหา</button>
                    </div>
                </div>
                <div class="hr-line-dashed"></div>
            </form>
            <div id="result"></div>
        </div>
    </div>

    <table *ngIf="userData" class="table table table-hover"><!--class="table table-striped"-->
        <thead>
            <tr>
                <th scope="col"><span>เบอร์โทร</span></th>
                <th scope="col" ><span>User</span></th>
                <th scope="col" ><span>ชื่อ</span></th>
                <th scope="col" ><span>สมัครวันที่</span></th>
                <th scope="col" ><span>เติมเงินล่าสุด</span></th>
                <th scope="col" ><span>ยอดฝากรวม</span></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let items of userData" >
                <th scope="row">{{ items.tel_no }}</th>
                <td>{{ items.username }}</td>
                <td>{{ items.real_name }}</td>
                <td>{{ items.register_date }}</td>
                <td>{{ items.last_deposit }}</td>
                <td *ngIf="items.deposit_total > 1">มีการเติมมาแล้ว</td>
                <td *ngIf="items.deposit_total < 1">ยังไม่มีการเติม</td>
                <!-- <td>{{ items.deposit_total | number }}</td> -->
            </tr>
        </tbody>
    </table>
</div>
</div>
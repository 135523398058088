<div class="jumbotron" style="background-color: #fff; height: calc(95vh);"> 
    <div class="row">
      <div class="col-3">
        <div class="card text-center text-white mb-4" 
        style="border-radius: 30px;background-color: #343a40;" 
        (click)="gotoPreviousDay(now)">
            <div class="card-body">
                <<
            </div>
        </div>
      </div>
      <div class="col-6 text-center">
        <h1>{{dateDay_Word}} {{dateDay_Num}} {{dateMonth_Word}}</h1>
      </div>
      <div class="col-3">
        <div class="card text-center text-white mb-4" 
        style="border-radius: 30px;background-color: #343a40;" 
        (click)="gotoNextDay(now)">
            <div class="card-body">
                >>
            </div>
        </div>
      </div>
    </div>
    <!-- <mat-progress-spinner style="margin:0 auto;" mode="indeterminate"></mat-progress-spinner> -->
    <!--Stock List-->
    <div class="row" *ngFor="let item of stockList" >
        <div class="col-5">
          <div class="card text-white text-center mb-4" style="border-radius: 10px;" [ngClass]="[item.bgcolor]">
              <div class="card-body">
                <h1>{{item.stockName}}</h1>
              </div>
          </div>
        </div>
        <div class="col-7 text-center">
            <input type="number" placeholder=" " class="input-big text-center" [(ngModel)]="item.total">
          <!-- <div class="card text-center mb-4" style="border-radius: 10px;" [ngClass]="[item.bgcolor]">
              <div class="card-body">
                  <input type="number" class="input-big" [(ngModel)]="item.total">
              </div>
          </div> -->
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="btn_action == 'add'">
            <div class="card text-center text-white mb-4" (click)="onAddDataStock()"
            style="border-radius: 20px;background-color: #343a40;">
                <div class="card-body">
                  <h1>เพิ่มข้อมุลหวยหุ้น</h1>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="btn_action == 'update'"> 
          <div class="card text-center text-white mb-4" (click)="onUpdateDataStock()"
          style="border-radius: 20px;background-color: #343a40;">
              <div class="card-body">
                <h1>อัพเดทข้อมุลหวยหุ้น</h1>
              </div>
          </div>
      </div>
    </div>
</div>
<div class="overlay" *ngIf="progressSpinner">
  <div class="center">
    <mat-progress-spinner diameter=50 mode="indeterminate" color="accent" value="20">
  </mat-progress-spinner> 
  </div>
</div>

<!-- <mat-progress-spinner mode="indeterminate"></mat-progress-spinner> -->
<!-- <mat-progress-spinner style="margin:0 auto;" mode="indeterminate"></mat-progress-spinner> -->
<!-- <div class="wrapper">
  <div class="inner">INNER CONTENT</div>
</div> -->
<!-- <mat-spinner></mat-spinner> -->

<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->
<div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <div class="card shadow-lg border-0 rounded-lg mt-5">
                    <div class="card-header"><h3 class="text-center font-weight-light my-4">Login</h3></div>
                    <div class="card-body">
                        <form>
                            <div class="form-group">
                                <label class="small mb-1" for="inputEmailAddress">User Login</label>
                                <input class="form-control py-4" name="userlogin" [(ngModel)]="userlogin" placeholder="Enter User Login" />
                            </div>
                            <div class="form-group">
                                <label class="small mb-1" for="inputPassword">Password</label>
                                <input class="form-control py-4" name="password" [(ngModel)]="password" placeholder="Enter Password" />
                            </div>
                            <!-- <div class="form-group">
                                <div class="custom-control custom-checkbox"><input class="custom-control-input" id="rememberPasswordCheck" type="checkbox" /><label class="custom-control-label" for="rememberPasswordCheck">Remember password</label></div>
                            </div> -->
                            <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                <a class="small" routerLink="/auth/forgot-password">Forgot Password?</a>
                                <!-- <a class="btn btn-primary " (click)="onLogin()">Login</a> -->
								<button mat-raised-button (click)="onLogin()"> Login </button>
                            </div>
                        </form>
                    </div>
                    <!-- <div class="card-footer text-center">
                        <div class="small"><a routerLink="/auth/register">Need an account? Sign up!</a></div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
<!-- <div class="container mt-5 mb-5">
	<div class="center">
		<div class="row">
			<div class="col-sm-12">
				<input type="text" class="form-control" name="userlogin" 
				[(ngModel)]="userlogin" placeholder="User Login">
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<input type="text" class="form-control" name="password" [(ngModel)]="password" placeholder="Password">
			</div>
		</div>
		<br>
		<button mat-raised-button style="width: 100%;" (click)="onLogin()"> Login </button>
	</div>
</div> -->


<!-- <div class="text-muted mt-5 mb-5 text-center small">by : <a class="text-muted" target="_blank" href="http://totoprayogo.com">totoprayogo.com</a></div> -->
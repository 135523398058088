<div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->
      <h2 class="active"> Register </h2>
  
      <!-- Login Form -->
      <form>
        <input type="text" class="fadeIn second" [(ngModel)]="userlogin" [ngModelOptions]="{standalone: true}" placeholder="login">
        <input type="text" class="fadeIn third" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" placeholder="password">
        <input type="text" class="fadeIn third" [(ngModel)]="name" [ngModelOptions]="{standalone: true}" placeholder="name">
        
        <input type="submit" class="fadeIn fourth" (click)="onRegister()" value="Register">
      </form>
  
      <!-- Remind Passowrd -->
      <!-- <div id="formFooter">
        <a class="underlineHover" (click)="onForgot()">Forgot Password?</a>
      </div> -->
  
    </div>
  </div>
  <div class="overlay" *ngIf="progressSpinner">
    <div class="center">
      <mat-progress-spinner diameter=50 mode="indeterminate" color="accent" value="20">
    </mat-progress-spinner> 
    </div>
  </div>
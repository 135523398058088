import { LoginService } from './../services/login.service';
import { DataService } from './../services/data.service';
import { Component, OnInit } from '@angular/core';

import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { ActivatedRoute, Router } from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  [x: string]: any;

  public now: Date = new Date();
  countMonth = 1;
  sumCardDownJone: any;
  sumCardNikkei01: any;
  data01: any; data02: any; data03: any; data04: any;
  data05: any; data06: any; data07: any; data08: any;
  data09: any; data10: any; data11: any; data12: any;
  data13: any; data14: any; data15: any; data16: any;
  ldata01: any; ldata02: any; ldata03: any; ldata04: any;
  ldata05: any; ldata06: any; ldata07: any; ldata08: any;
  ldata09: any; ldata10: any; ldata11: any; ldata12: any;
  ldata13: any; ldata14: any; ldata15: any; ldata16: any;
  stockList = [
    {stockName: 'ดาวโจนส์', bgcolor:'gray-color', total: 0, percent: 0, countcss: 'count-dj', countpersentcss: 'count-dj-percent', sid: 'usa'},
    {stockName: 'นิเคอิ - เช้า', bgcolor:'redlight-color', total: 0, percent: 0, countcss: 'count-ni-01', countpersentcss: 'count-ni01-percent', sid: 'jp01'},
    {stockName: 'จีน - เช้า', bgcolor:'redlight-color', total: 0, percent: 0, countcss: 'count-cn-01', countpersentcss: 'count-cn01-percent', sid: 'cn01'},
    {stockName: 'ฮั่งเส็ง - เช้า', bgcolor:'redlight-color', total: 0, percent: 0, countcss: 'count-hk-01', countpersentcss: 'count-hk01-percent', sid: 'hk01'},
    {stockName: 'ไต้หวัน', bgcolor:'skyblue-color', total: 0, percent: 0, countcss: 'count-tw', countpersentcss: 'count-tw-percent', sid: 'tw'},
    {stockName: 'เกาหลี', bgcolor:'skyblue-color', total: 0, percent: 0, countcss: 'count-kr', countpersentcss: 'count-kr-percent', sid: 'kr'},
    {stockName: 'นิเคอิ - บ่าย', bgcolor:'bg-warning', total: 0, percent: 0, countcss: 'count-ni-02', countpersentcss: 'count-ni02-percent', sid: 'jp02'},
    {stockName: 'จีน - บ่าย', bgcolor:'bg-warning', total: 0, percent: 0, countcss: 'count-cn-02', countpersentcss: 'count-cn02-percent', sid: 'cn02'},
    {stockName: 'ฮั่งเส็ง - บ่าย', bgcolor:'bg-warning', total: 0, percent: 0, countcss: 'count-hk-02', countpersentcss: 'count-hk02-percent', sid: 'hk02'},
    {stockName: 'สิงค์โปร', bgcolor:'bg-primary', total: 0, percent: 0, countcss: 'count-sg', countpersentcss: 'count-sg-percent', sid: 'sg'},
    {stockName: 'ไทย', bgcolor:'fuchsia-color', total: 0, percent: 0, countcss: 'count-th', countpersentcss: 'count-th-percent', sid: 'th'},
    {stockName: 'รัสเซีย', bgcolor:'bg-success', total: 0, percent: 0, countcss: 'count-ru', countpersentcss: 'count-ru-percent', sid: 'ru'},
    {stockName: 'เยอรมัน', bgcolor:'bg-success', total: 0, percent: 0, countcss: 'count-ger', countpersentcss: 'count-ger-percent', sid: 'ger'},
    {stockName: 'อังกฤษ', bgcolor:'bg-success', total: 0, percent: 0, countcss: 'count-eng', countpersentcss: 'count-eng-percent', sid: 'eng'},
    {stockName: 'อินเดีย', bgcolor:'bg-danger', total: 0, percent: 0, countcss: 'count-in', countpersentcss: 'count-in-percent', sid: 'in'},
    {stockName: 'อียิปต์', bgcolor:'bg-danger', total: 0, percent: 0, countcss: 'count-eg', countpersentcss: 'count-eg-percent', sid: 'eg'}
  ];
  progressSpinner = true;
  allTotal = 0;

  arrayDate;
  dateDay_Word: any;
  dateDay_Num: any;
  dateMonth_Word: any;
  dateMonth_Num: any;
  dateYear_Num: any;
  thisMonth: any;
  thisYear: any;showFiller = true;

  constructor(private afdb: AngularFireDatabase,
    private dataService: DataService,
    private router: Router,
    private loginService: LoginService) {

  }

  async ngOnInit(){
    this.thisYear = this.getDateDay('y');
    this.thisMonth = this.getDateDay('m');
    let lastMonth = Number(this.getDateDay('m')) -1;
    let lastYear;
    if(this.thisMonth == '01'){
      lastMonth = 12;
      lastYear = Number(this.thisYear) -1;
    }else{
      lastYear = this.thisYear;
    }
    //Get Data ThisMonth
    // this.getStockThisMonth(this.thisYear, this.thisMonth);

    this.progressSpinner = false;
  }

  async getStockThisMonth(thisYear, thisMonth){
    this.data01 = await this.dataService.getStockThisMonth('usa', thisYear, thisMonth);
    this.data02 = await this.dataService.getStockThisMonth('jp01', thisYear, thisMonth);
    this.data03 = await this.dataService.getStockThisMonth('cn01', thisYear, thisMonth);
    this.data04 = await this.dataService.getStockThisMonth('hk01', thisYear, thisMonth);
    this.data05 = await this.dataService.getStockThisMonth('tw', thisYear, thisMonth);
    this.data06 = await this.dataService.getStockThisMonth('kr', thisYear, thisMonth);
    this.data07 = await this.dataService.getStockThisMonth('jp02', thisYear, thisMonth);
    this.data08 = await this.dataService.getStockThisMonth('cn02', thisYear, thisMonth);
    this.data09 = await this.dataService.getStockThisMonth('hk02', thisYear, thisMonth);
    this.data10 = await this.dataService.getStockThisMonth('sg', thisYear, thisMonth);
    this.data11 = await this.dataService.getStockThisMonth('th', thisYear, thisMonth);
    this.data12 = await this.dataService.getStockThisMonth('ru', thisYear, thisMonth);
    this.data13 = await this.dataService.getStockThisMonth('ger', thisYear, thisMonth);
    this.data14 = await this.dataService.getStockThisMonth('eng', thisYear, thisMonth);
    this.data15 = await this.dataService.getStockThisMonth('in', thisYear, thisMonth);
    this.data16 = await this.dataService.getStockThisMonth('eg', thisYear, thisMonth);

    //Sum Total ThisMonth
    this.stockList[0].total = this.sumThisMonth(this.data01);
    this.stockList[1].total = this.sumThisMonth(this.data02);
    this.stockList[2].total = this.sumThisMonth(this.data03);
    this.stockList[3].total = this.sumThisMonth(this.data04);
    this.stockList[4].total = this.sumThisMonth(this.data05);
    this.stockList[5].total = this.sumThisMonth(this.data06);
    this.stockList[6].total = this.sumThisMonth(this.data07);
    this.stockList[7].total = this.sumThisMonth(this.data08);
    this.stockList[8].total = this.sumThisMonth(this.data09);
    this.stockList[9].total = this.sumThisMonth(this.data10);
    this.stockList[10].total = this.sumThisMonth(this.data11);
    this.stockList[11].total = this.sumThisMonth(this.data12);
    this.stockList[12].total = this.sumThisMonth(this.data13);
    this.stockList[13].total = this.sumThisMonth(this.data14);
    this.stockList[14].total = this.sumThisMonth(this.data15);
    this.stockList[15].total = this.sumThisMonth(this.data16);
    for (let j = 0; j < this.stockList.length; j++) {
      // if(this.stockList[j].total !=){
        this.allTotal = this.allTotal + Number(this.stockList[j].total);
      // }
      this.animateValue(this.stockList[j].countcss, 0, this.stockList[j].total, 2000, 'money');
    }
  }

  async getStockLastMonth(lastYear, lastMonth){
    this.ldata01 = await this.dataService.getStockThisMonth('usa', lastYear, lastMonth);
    this.ldata02 = await this.dataService.getStockThisMonth('jp01', lastYear, lastMonth);
    this.ldata03 = await this.dataService.getStockThisMonth('cn01', lastYear, lastMonth);
    this.ldata04 = await this.dataService.getStockThisMonth('hk01', lastYear, lastMonth);
    this.ldata05 = await this.dataService.getStockThisMonth('tw', lastYear, lastMonth);
    this.ldata06 = await this.dataService.getStockThisMonth('kr', lastYear, lastMonth);
    this.ldata07 = await this.dataService.getStockThisMonth('jp02', lastYear, lastMonth);
    this.ldata08 = await this.dataService.getStockThisMonth('cn02', lastYear, lastMonth);
    this.ldata09 = await this.dataService.getStockThisMonth('hk02', lastYear, lastMonth);
    this.ldata10 = await this.dataService.getStockThisMonth('sg', lastYear, lastMonth);
    this.ldata11 = await this.dataService.getStockThisMonth('th', lastYear, lastMonth);
    this.ldata12 = await this.dataService.getStockThisMonth('ru', lastYear, lastMonth);
    this.ldata13 = await this.dataService.getStockThisMonth('ger', lastYear, lastMonth);
    this.ldata14 = await this.dataService.getStockThisMonth('eng', lastYear, lastMonth);
    this.ldata15 = await this.dataService.getStockThisMonth('in', lastYear, lastMonth);
    this.ldata16 = await this.dataService.getStockThisMonth('eg', lastYear, lastMonth);
  }

  sumThisMonth(data: any){
    let sumThisMonth = 0;
    Object.keys(data).forEach(key=> {
      sumThisMonth = sumThisMonth + data[key].value;
    });
    return (sumThisMonth);
  }

  getDateDay(choose: any){
    let date: Date = new Date();
    let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    let YMD = ionicDate.toISOString().split('T');
    YMD = YMD[0].split('-');
    if(choose == 'd')
      return YMD[2].toString();
    if(choose == 'm')
      return YMD[1].toString();
    if(choose == 'y')
      return YMD[0].toString();
  }

  animateValue(id, start, end, duration, type) {
    // assumes integer values for start and end
    
    var obj = document.getElementById(id);
    var range = end - start;
    // no timer shorter than 50ms (not really visible any way)
    var minTimer = 50;
    // calc step time to show all interediate values
    var stepTime = Math.abs(Math.floor(duration / range));
    
    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);
    
    // get current time and calculate desired end time
    var startTime = new Date().getTime();
    var endTime = startTime + duration;
    var timer;
  
    function run() {
        var now = new Date().getTime();
        var remaining = Math.max((endTime - now) / duration, 0);
        var value = Math.round(end - (remaining * range));
        if(type == 'money'){
          obj.innerHTML = '$ ' + value.toLocaleString('en-US');
        }
        if(type == 'percent'){
          obj.innerHTML = value.toLocaleString('en-US') + '%';
        }
        // obj.innerHTML = '$ ' + value.toLocaleString('en-IN');
        if (value == end) {
            clearInterval(timer);
        }
    }
    
    timer = setInterval(run, stepTime);
    run();
  }

  gotoStockDetail(item){
    // console.log(item);
    this.router.navigate(['/detail/' + item.sid], {
      state: {
        item: item,
        years: this.thisYear,
        month: this.thisMonth,
        befor_page: '/'
      }
    });
  }

  showDate(now){
    this.arrayDate = now.toDateString().split(" ");
    this.dateDay_Word = this.arrayDate[0];
    this.dateMonth_Word = this.arrayDate[1];
    this.dateDay_Num = this.arrayDate[2];
    this.dateYear_Num = this.arrayDate[3];

    let timestamp = now.getTime();
    var date = new Date(timestamp).toLocaleDateString()
    this.arrayDate = date.split("/");
    this.dateMonth_Num = this.arrayDate[0];// mm/dd/yyy
    return date;
    // console.log(date)
  }

  gotoPreviousDay(){
    this.progressSpinner = true;

    this.countMonth = this.countMonth - 1;
    let makeDate = new Date();
    makeDate = new Date(makeDate.setMonth(makeDate.getMonth() + this.countMonth));
    this.thisMonth = makeDate.getMonth();
    this.thisYear = makeDate.getFullYear();
    if(this.thisMonth == 0){
      this.thisMonth = 12;
      this.thisYear = makeDate.getFullYear() -1;
    }
    console.log('makeDate : ', this.thisMonth);
    console.log('thisYear : ', this.thisYear);
    this.getStockThisMonth(this.thisYear, this.thisMonth);
    this.progressSpinner = false;
  }

  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  
  onLogout(){
    this.loginService.logout();
  }

}

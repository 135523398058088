import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  
  constructor(private afdb: AngularFireDatabase,
    private afAuth: AngularFireAuth,) { }

  getItemsData(itemsStatus): Promise<any> {
    return new Promise(resolve => {
      this.afdb.list('/stock/' , ref => ref.orderByChild('status').equalTo(itemsStatus)).snapshotChanges().subscribe((data: any)=>{
        resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
      });
    })
  }

  // getTimeline(key): Promise<any> {
  //   return new Promise(resolve => {
  //     this.afdb.list('/stock/timeline/' + key).snapshotChanges().subscribe((data: any)=>{
  //       resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
  //       // resolve(data.payload.val());
  //     });
  //   })
  // }

  getTimeline(key){
    return this.afdb.list('/stock/timeline/' + key);
  }

  addItems2Stock(team, itemsName, itemsHolder, itemsPlace, itemsDetail){
    this.afdb.object('/stock/').update({
      dateCreate: this.getIonicDateTime(),
      team,
      itemsName,
      itemsHolder,
      itemsPlace,
      itemsDetail,
    });
    // this.afdb.list('/huay/stock/' + sid + '/' + y + '/' + m + '/' + d).push({value});
  }

  getIonicDateTime(): string {
    let date: Date = new Date();
    let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return ionicDate.toISOString();
  }

  addHistory(actionContent, link){
    let datecreated = this.getIonicDateTime();
    let user: any = firebase.auth().currentUser;
    this.afdb.list('/stock/history').push({
      dateCreate: this.getIonicDateTime(),
      userAction: user.uid,
      actionContent: actionContent,
      link: link
    }).then((data: any) => {
      console.log(actionContent , 'OK');
    })
  }

}


import { DataService } from './../services/data.service';
import { Component, OnInit } from '@angular/core';
import {formatDate } from '@angular/common';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';

@Component({
  selector: 'app-addstock',
  templateUrl: './addstock.component.html',
  styleUrls: ['./addstock.component.css']
})
export class AddstockComponent implements OnInit {

  public now: Date = new Date();
  arrayDate;
  dateDay_Word: any;
  dateDay_Num: any;
  dateMonth_Word: any;
  dateMonth_Num: any;
  dateYear_Num: any;

  txt_downjone: string;
  stockList = [
    {SID: 'usa', stockName: 'ดาวโจนส์', bgcolor:'gray-color', total: '', key: ''},
    {SID: 'jp01', stockName: 'นิเคอิ - เช้า', bgcolor:'redlight-color', total: '', key: ''},
    {SID: 'cn01', stockName: 'จีน - เช้า', bgcolor:'redlight-color', total: '', key: ''},
    {SID: 'hk01', stockName: 'ฮั่งเส็ง - เช้า', bgcolor:'redlight-color', total: '', key: ''},
    {SID: 'tw', stockName: 'ไต้หวัน', bgcolor:'skyblue-color', total: '', key: ''},
    {SID: 'kr', stockName: 'เกาหลี', bgcolor:'skyblue-color', total: '', key: ''},
    {SID: 'jp02', stockName: 'นิเคอิ - บ่าย', bgcolor:'bg-warning', total: '', key: ''},
    {SID: 'cn02', stockName: 'จีน - บ่าย', bgcolor:'bg-warning', total: '', key: ''},
    {SID: 'hk02', stockName: 'ฮั่งเส็ง - บ่าย', bgcolor:'bg-warning', total: '', key: ''},
    {SID: 'sg', stockName: 'สิงค์โปร', bgcolor:'bg-primary', total: '', key: ''},
    {SID: 'th', stockName: 'ไทย', bgcolor:'fuchsia-color', total: '', key: ''},
    {SID: 'ru', stockName: 'รัสเซีย', bgcolor:'bg-success', total: '', key: ''},
    {SID: 'ger', stockName: 'เยอรมัน', bgcolor:'bg-success', total: '', key: ''},
    {SID: 'eng', stockName: 'อังกฤษ', bgcolor:'bg-success', total: '', key: ''},
    {SID: 'in', stockName: 'อินเดีย', bgcolor:'bg-danger', total: '', key: ''},
    {SID: 'eg', stockName: 'อียิปต์', bgcolor:'bg-danger', total: '', key: ''}
  ];

  btn_action = 'add';
  progressSpinner = true;

  constructor(private dataService: DataService,
    private router: Router,){
    console.log('constructor');
    let queryDate = this.showDate(this.now);
    this.getdataStocklist(queryDate);

    // this.todaysDataTime = formatDate(this.now, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0700');
    // this.arrayDate = this.now.toDateString().split(" ");
    // this.dateDay_Word = this.arrayDate[0];
    // this.dateMonth_Word = this.arrayDate[1];
    // this.dateDay_Num = this.arrayDate[2];
    // this.dateYear_Num = this.arrayDate[3];
    // let timestamp = this.now.getTime();
    // console.log('timestamp : ', timestamp);
    // var date = new Date(timestamp).toLocaleDateString();
    // console.log('date : ', date);
  }

  ngOnInit(){
    console.log('ngOnInit');
  }

  showDate(now){
    this.arrayDate = now.toDateString().split(" ");
    this.dateDay_Word = this.arrayDate[0];
    this.dateMonth_Word = this.arrayDate[1];
    this.dateDay_Num = this.arrayDate[2];
    this.dateYear_Num = this.arrayDate[3];

    let timestamp = now.getTime();
    var date = new Date(timestamp).toLocaleDateString()
    this.arrayDate = date.split("/");
    this.dateMonth_Num = this.arrayDate[0];// mm/dd/yyy
    return date;
    // console.log(date)
  }

  gotoPreviousDay(nowDate){
    this.progressSpinner = true;
    this.btn_action = 'add';

    nowDate.setDate(nowDate.getDate() - 1);// Yesterday
    console.log('Yesterday :', nowDate.toString());
    // let timestamp = nowDate.getTime();// Date2Timestamp
    // console.log('timestamp : ', timestamp);
    this.now = nowDate;
    let queryDate = this.showDate(this.now);
    this.getdataStocklist(queryDate);
  }

  gotoNextDay(nowDate){
    this.progressSpinner = true;
    this.btn_action = 'add';

    nowDate.setDate(nowDate.getDate() + 1);// Tomorrow
    console.log('Tomorrow :', nowDate.toString());
    this.now = nowDate;
    let queryDate = this.showDate(this.now);
    this.getdataStocklist(queryDate);
  }

  getdataStocklist(date){
    this.stockList.forEach(arraystock => {
      // console.log('element : ', element.SID);
      this.dataService.getDataStockToday(arraystock.SID, date).then((data: any)=>{
        let dataStock = data.map(c => ({ key: c.key, ...c.payload.val() }));
        if(dataStock.length > 0){
          this.btn_action = 'update';
          dataStock.forEach(element => {
            console.log(element.value);
            arraystock.total = element.value;
            arraystock.key = element.key;
          });
        }else{
          arraystock.total = '';
        }
      }).finally(()=>{
        this.progressSpinner = false;
      })
    });
    // console.log('stockList : ', this.stockList);
  }

  onAddDataStock(){
    Swal.fire({
      title: 'กำลังทำการบันทึกข้อมูล',
      html: 'I will close in <b></b> milliseconds.',
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
        let timestamp = this.now.getTime();
        var date = new Date(timestamp).toLocaleDateString()
        // console.log(date)
        this.stockList.forEach(element => {
          console.log('element : ', element.SID);
          this.dataService.addDataStock(element.SID, date, timestamp, this.dateDay_Word, element.total);
          this.dataService.addDataStock2(element.SID, this.dateYear_Num, this.dateMonth_Num, this.dateDay_Num, element.total);
        });
      },
      willClose: () => {
        Swal.fire(
          'Succress!',
          'บันทึกลงฐานข้อมูลสำเร็จ',
          'success'
        ).then((result) => {
          this.btn_action = 'update';
          // this.router.navigate(['/addstock']);
          // window.location.reload(); 
        })
      }
    })
  }

  onUpdateDataStock(){
    Swal.fire({
      title: 'กำลังทำการอัพเดทข้อมูล',
      html: 'I will close in <b></b> milliseconds.',
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
        let timestamp = this.now.getTime();
        var date = new Date(timestamp).toLocaleDateString()
        // console.log(date)
        this.stockList.forEach(element => {
          console.log('element : ', element);
          this.dataService.updateDataStock(element.SID, date, timestamp, this.dateDay_Word, element.total, element.key);
          this.dataService.updateDataStock2(element.SID, this.dateYear_Num, this.dateMonth_Num, this.dateDay_Num, element.total);

        });
      },
      willClose: () => {
        Swal.fire(
          'Succress!',
          'อัพเดทลงฐานข้อมูลสำเร็จ',
          'success'
        ).then((result) => {
          // this.router.navigate(['/addstock']);
          window.location.reload();
        })
      }
    })
  }

  getIonicDateTime(): string {
    let date: Date = new Date();
    let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return ionicDate.toISOString();
  }

}

import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Chart } from 'chart.js';
import { DataService } from '../services/data.service';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit, AfterViewInit {

  @ViewChild('myAreaChart') myAreaChart!: ElementRef<HTMLCanvasElement>;
  chart!: Chart;
  item: any;
  datathisMonth: any;
  thisMonth: any;
  thisYear: any;
  befor_page: any;
  average: any;
  xMonth = [];
  valueMonth = [];
  valuePreviousMonth = [];
  a: any;
  b: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private afdb: AngularFireDatabase
    ){ 
    if (this.router.getCurrentNavigation().extras.state) {
      this.item = this.router.getCurrentNavigation().extras.state.item;
      this.thisMonth = this.router.getCurrentNavigation().extras.state.month;
      this.thisYear = this.router.getCurrentNavigation().extras.state.years;
      this.befor_page = this.router.getCurrentNavigation().extras.state.befor_page;
    }
    console.log('detail :', this.item);
    
  }

  async ngOnInit() {
    // let sid = this.route.snapshot.params.id;
    // console.log('sid :', sid);
    // this.datathisMonth = await this.dataService.getStockThisMonth(sid, this.thisYear, this.thisMonth);
    // console.log('datathisMonth :', this.datathisMonth);
  }

  async ngAfterViewInit() {
    console.log('ngAfterViewInit :');
    let sid = this.route.snapshot.params.id;
    console.log('sid :', sid);

    let lastMonth, lastYear;
    if(this.thisYear == undefined){
      this.thisYear = this.getDateDay('y');
      this.thisMonth = this.getDateDay('m');
      lastMonth = Number(this.getDateDay('m')) -1;
      if(this.thisMonth == '01'){
        lastMonth = 12;
        lastYear = Number(this.thisYear) -1;
      }else{
        lastYear = this.thisYear;
      }
    }
    if(this.thisMonth == 1){
      lastMonth = 12;
      lastYear = this.thisYear -1;
    }else{
      lastMonth = this.thisMonth -1;;
      lastYear = this.thisYear;
    }
   
    this.afdb.list('/huay/stock/' + sid + '/' + this.thisYear + '/' + this.thisMonth).snapshotChanges().subscribe((data: any)=>{
      if(data != null)
        this.a = data.map(c => ({ key: c.key, ...c.payload.val() }));
        data.map(c => ({ key: c.key, ...c.payload.val() })).forEach(element => {
          // this.xMonth.push(element.key);
          // this.valueMonth.push(element.value);
        });
        this.afdb.list('/huay/stock/' + sid + '/' + lastYear + '/' + lastMonth).snapshotChanges().subscribe((dataPrevious: any)=>{
          if(data != null)
            this.b = dataPrevious.map(c => ({ key: c.key, ...c.payload.val() }));
            dataPrevious.map(c => ({ key: c.key, ...c.payload.val() })).forEach(element => {
              // this.valuePreviousMonth.push(element.value);
            });
            console.log(this.b);

            for (let index = 0; index <= 31; index++) {
              if(this.a[index] == undefined){
                this.xMonth.push(index);
                this.valueMonth.push(0);
              }else{
                if(this.a[index].key == (index+1)){
                  this.xMonth.push(this.a[index].key);
                  this.valueMonth.push(this.a[index].value);
                }else{
                  this.xMonth.push(index);
                  this.valueMonth.push(0);
                }
              }
              if(this.b[index] == undefined){
                this.valuePreviousMonth.push(0);
              }else{
                if(this.b[index].key == (index+1)){
                  this.valuePreviousMonth.push(this.b[index].value);
                }else{
                  this.valuePreviousMonth.push(0);
                }
              }
            }
            console.log(this.valuePreviousMonth);
            this.average = this.item.total / this.a.length;
            let max = Math.max(...this.valueMonth);
            max = max + (max/2);
            this.graphLine(this.xMonth, this.valueMonth, this.valuePreviousMonth, max);
        });
        // this.average = this.item.total / this.xMonth.length;
        // this.graphLine(this.xMonth, this.valueMonth);

    });
    // this.chart = new Chart(this.myAreaChart.nativeElement, {
    //     type: 'line',
    //     data: {
    //         // labels: [
    //         //     'Mar 1',
    //         //     'Mar 2',
    //         //     'Mar 3',
    //         //     'Mar 4',
    //         //     'Mar 5',
    //         //     'Mar 6',
    //         //     'Mar 7',
    //         //     'Mar 8',
    //         //     'Mar 9',
    //         //     'Mar 10',
    //         //     'Mar 11',
    //         //     'Mar 12',
    //         //     'Mar 13',
    //         // ],
    //         labels: this.xMonth,
    //         datasets: [
    //             {
    //                 label: 'Sessions',
    //                 lineTension: 0.3,
    //                 backgroundColor: 'rgba(2,117,216,0.2)',
    //                 borderColor: 'rgba(2,117,216,1)',
    //                 pointRadius: 5,
    //                 pointBackgroundColor: 'rgba(2,117,216,1)',
    //                 pointBorderColor: 'rgba(255,255,255,0.8)',
    //                 pointHoverRadius: 5,
    //                 pointHoverBackgroundColor: 'rgba(2,117,216,1)',
    //                 pointHitRadius: 50,
    //                 pointBorderWidth: 2,
    //                 data: [
    //                     10000,
    //                     30162,
    //                     26263,
    //                     18394,
    //                     18287,
    //                     28682,
    //                     31274,
    //                     33259,
    //                     25849,
    //                     24159,
    //                     32651,
    //                     31984,
    //                     38451,
    //                 ],
    //             },
    //             {
    //               label: 'Sessions',
    //               lineTension: 0.3,
    //               backgroundColor: 'rgba(2,117,216,0.2)',
    //               borderColor: 'rgba(2,117,216,1)',
    //               pointRadius: 5,
    //               pointBackgroundColor: 'rgba(2,117,216,1)',
    //               pointBorderColor: 'rgba(255,255,255,0.8)',
    //               pointHoverRadius: 5,
    //               pointHoverBackgroundColor: 'rgba(2,117,216,1)',
    //               pointHitRadius: 50,
    //               pointBorderWidth: 2,
    //               data: [
    //                   10000,
    //                   20162,
    //                   16263,
    //                   38394,
    //                   38287,
    //                   18682,
    //                   21274,
    //                   33259,
    //                   15849,
    //                   14159,
    //                   12651,
    //                   11984,
    //                   18451,
    //               ],
    //             },
    //         ],
    //     },
    //     options: {
    //         responsive: true,
    //         title: {
    //           display: true,
    //           text: 'Chart.js Line Chart'
    //         },
    //         tooltips: {
    //           mode: 'index',
    //           intersect: false,
    //         },
    //         hover: {
    //           mode: 'nearest',
    //           intersect: true
    //         },
    //         scales: {
    //             xAxes: [
    //                 {
    //                     time: {
    //                         unit: 'day',
    //                     },
    //                     gridLines: {
    //                         display: false,
    //                     },
    //                     ticks: {
    //                         maxTicksLimit: 7,
    //                     },
    //                 },
    //             ],
    //             yAxes: [
    //                 {
    //                     ticks: {
    //                         min: 0,
    //                         max: 40000,
    //                         maxTicksLimit: 5,
    //                     },
    //                     gridLines: {
    //                         color: 'rgba(0, 0, 0, .125)',
    //                     },
    //                 },
    //             ],
    //         },
    //         legend: {
    //             display: false,
    //         },
    //     },
    // });
  }

  graphLine(xMonth, valueMonth, valuePreviousMonth, maxValue){
    this.chart = new Chart(this.myAreaChart.nativeElement, {
      type: 'line',
      data: {
          labels: xMonth,
          datasets: [
              {
                  label: 'เดือนนี้',
                  lineTension: 0.3,
                  backgroundColor: 'rgba(2,117,216,0.2)',
                  borderColor: 'rgba(2,117,216,1)',
                  pointRadius: 5,
                  pointBackgroundColor: 'rgba(2,117,216,1)',
                  pointBorderColor: 'rgba(255,255,255,0.8)',
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: 'rgba(2,117,216,1)',
                  pointHitRadius: 50,
                  pointBorderWidth: 2,
                  data: valueMonth,
              },
              {
                label: 'เดือนที่แล้ว',
                lineTension: 0.3,
                backgroundColor: 'rgba(216,142,2,0.2)',
                borderColor: 'rgba(216,142,2,1)',
                pointRadius: 5,
                pointBackgroundColor: 'rgba(216,142,2,1)',
                pointBorderColor: 'rgba(255,255,255,0.8)',
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(216,142,2,1)',
                pointHitRadius: 50,
                pointBorderWidth: 2,
                data: valuePreviousMonth,
              },
          ],
      },
      options: {
          responsive: true,
          title: {
            display: true,
            text: 'Chart.js Line Chart'
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
              xAxes: [
                  {
                      time: {
                          unit: 'day',
                      },
                      gridLines: {
                          display: false,
                      },
                      ticks: {
                          maxTicksLimit: 7,
                      },
                  },
              ],
              yAxes: [
                  {
                      ticks: {
                          min: 0,
                          max: maxValue,
                          maxTicksLimit: 5,
                      },
                      gridLines: {
                          color: 'rgba(0, 0, 0, .125)',
                      },
                  },
              ],
          },
          legend: {
              display: false,
          },
      },
    });
  }

  getDateDay(choose: any){
    let date: Date = new Date();
    let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    let YMD = ionicDate.toISOString().split('T');
    YMD = YMD[0].split('-');
    if(choose == 'd')
      return YMD[2].toString();
    if(choose == 'm')
      return YMD[1].toString();
    if(choose == 'y')
      return YMD[0].toString();
  }

}

<div *ngIf="loginStatus">
    <div class="jumbotron" style="background-color: #fff; height: calc(95vh);">
        <table *ngIf="userList" class="table table table-hover"><!--class="table table-striped"-->
            <thead>
                <tr>
                    <th scope="col"><span>เบอร์โทร</span></th>
                    <th scope="col" ><span>User</span></th>
                    <th scope="col" ><span>ชื่อ</span></th>
                    <th scope="col" ><span>สร้างวันที่</span></th>
                    <th scope="col" ><span>แก้ไขล่าสุด</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let items of userList" (click)="onClickuser(items)">
                    <th scope="row">{{ items.tel }}</th>
                    <td>{{ items.email }}</td>
                    <td>{{ items.name }}</td>
                    <td>{{ items.datecreated }}</td>
                    <td>{{ items.dateedited }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
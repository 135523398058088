<div class="loading-popup" *ngIf="showPopup">
    <div class="loading-content">
      <p>Loading...</p>
    </div>
</div>
<div *ngIf="loginStatus">
<div class="centercontainer">
    <div class="row">
        <div class="col-12">
            <input type="password" class="form-control" [(ngModel)]="pwdunlock" 
            [ngModelOptions]="{standalone: true}" placeholder="password"
            (change)="onChangePWD($event)">
        </div>
    </div>
    <br>
    <h5>วิธีใช้งาน : Version 1.1.0 (ByPass Login)</h5>
    <h5>- เลือกเว็บไซร้ ที่จะส่ง API</h5>
    <h5>- ให้ทำกรอกเลข Agent และ Token ลงช่องว่าง ก่อนทำการ Uploadไฟล์</h5>
    <div class="row">
        <div class="col-6">
            <div class="funkyradio">
                <div class="funkyradio-default">
                    <div class="funkyradio-danger">
                        <input type="radio" name="webapi" id="radio1" [value]="weblottovip" [(ngModel)]="webapi" (change)="onClickRadio($event)" />
                        <label for="radio1">LottoVIP</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="funkyradio">
                <div class="funkyradio-warning">
                    <input type="radio" name="webapi" id="radio2" [value]="webruay" [(ngModel)]="webapi" (change)="onClickRadio($event)" />
                    <label for="radio2">RUAY</label>
                </div>
            </div>
        </div>
    </div>
    <!-- <button class="btn btn-primary btn-sm" (click)="onCilckCheck()" type="button" id="search">ค้นหา</button> -->
    <br>
    <div class="row">
        <div class="col-3">
            <input type="text" class="form-control" [disabled]="disabledItem"
            [(ngModel)]="agent" [ngModelOptions]="{standalone: true}" placeholder="เลข AG">
        </div>
        <div class="col-9">
            <input type="text" class="form-control" [disabled]="disabledItem" 
            [(ngModel)]="token" [ngModelOptions]="{standalone: true}" placeholder="กรุณากรอก Token ก่อน Upload ไฟล์">
        </div>
    </div>
    <br>
    <input type="file" class="form-control" id="file" (change)="uploadListener($event)" [disabled]="disabledItem">
    <br>
    <button mat-raised-button style="width: 100%;" disabled="{{btn_disabled}}" (click)="downloadFile(lines)"> Download </button>
    
</div>
<div class="overlay" *ngIf="progressSpinner">
    <div class="centercontainer">
      <mat-progress-spinner diameter=50 mode="indeterminate" color="accent" value="20">
    </mat-progress-spinner> 
    </div>
</div>
</div>
<div *ngIf="loginStatus">
    <div class="jumbotron" style="background-color: #fff; height: calc(95vh);">
        <div class="ibox ">
            <div class="ibox-title">
                <h3>ค้าหาพัสดุ</h3>
            </div>
            <div class="ibox-content">
                <form id="customer_search_form">
                    <div class="form-group row"><label class="col-sm-2 col-form-label">ค้นหา</label>
                        <div class="col-sm-10">
                            <input class="form-control"  [(ngModel)]="searchitems" 
                            showCancelButton="focus" (ngModelChange)="searchContent($event)"
                            placeholder="ค้นหา" 
                            [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <div class="hr-line-dashed"></div>
                </form>
                <div id="result"></div>
            </div>
        </div>
    
        <table *ngIf="itemsData" class="table table table-hover"><!--class="table table-striped"-->
            <thead>
                <tr>
                    <th scope="col"><span>รายการ</span></th>
                    <th scope="col" ><span>ผู้ถือครอง</span></th>
                    <th scope="col" ><span>สถานที่</span></th>
                    <th scope="col" ><span>รายละเอียดเพิ่มเติม</span></th>
                </tr>
            </thead>
            <tbody *ngFor="let items of itemsData">
                <tr (click)="onTimeline(items)" >
                    <th >{{ items.itemsName }}</th>
                    <td>{{ items.itemsHolder }}</td>
                    <td>{{ items.itemsPlace }}</td>
                    <td>{{ items.itemsDetail }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common'

import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-checkuser-manual',
  templateUrl: './checkuser-manual.component.html',
  styleUrls: ['./checkuser-manual.component.css']
})
export class CheckuserManualComponent implements OnInit {

  // url = 'https://agenthuay.lottovip.com/api/simple/telfull/62/a3833f2674a55f7d5c63e10e84d62772/0894143710';
  loginStatus: any;
  usertel = '';
  userlogin = '';
  user = { login: '', tel: ''}
  // token = 'a3833f2674a55f7d5c63e10e84d62772';
  token = '';
  url = '/api/checkuser.php';
  userData: any;

  constructor(private http: HttpClient,
    private afAuth: AngularFireAuth,
    private router: Router,
    private location: Location,) { }

  ngOnInit(){
    this.afAuth.onAuthStateChanged(user => {
      // console.log('appComponent : ', user);
      if (user == null){
        this.loginStatus = true;
        // this.location.back()
        // this.router.navigate(['/login'])
      }else{
        this.loginStatus = true;
        console.log(this.loginStatus);
      }
    })
  }

  async onSearchUser(){
    if(this.usertel != '' && this.userlogin != ''){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'บอกว่าอย่าใส่สองช่อง เดี๋ยวเว็บล่มนะ !!',
        // footer: '<a href>Why do I have this issue?</a>'
      })
    }else{
      // let urlApi;
      if(this.usertel != ''){
        let urlApi = this.url + '?token=' + this.token + '&tel=' + this.usertel;
        console.log(urlApi);
        this.userData = await this.getDataUser(urlApi);
      }
      if(this.userlogin != ''){
        let urlApi = this.url + '?token=' + this.token + '&user=' + this.userlogin;
        console.log(urlApi);
        this.userData = await this.getDataUser(urlApi);
      }
    }

  }

  getDataUser(url): Promise<any> {
    return new Promise(resolve => {
      this.http.get(url).subscribe({
        next: data => {
            console.log(data);
            resolve(data);
        },
        error: error => {
            console.error('There was an error!', error);
        }
      })
    })
  }

}

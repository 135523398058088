<!-- <div *ngIf="loginStatus"> -->
    <div class="centercontainer">
        <div class="row">
            <div class="col-12">
                <div class="funkyradio">
                    <div class="funkyradio-default">
                        <div class="funkyradio-danger">
                            <input type="radio" name="team" id="radio1" [value]="teamwinwin893" [(ngModel)]="team" (change)="onClickRadio($event)" />
                            <label for="radio1">Team WINWIN893</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-6">
                <div class="funkyradio">
                    <div class="funkyradio-warning">
                        <input type="radio" name="webapi" id="radio2" [value]="webruay" [(ngModel)]="webapi" (change)="onClickRadio($event)" />
                        <label for="radio2">RUAY</label>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- <button class="btn btn-primary btn-sm" (click)="onCilckCheck()" type="button" id="search">ค้นหา</button> -->
        <br>
        <div class="row">
            <!-- <label class="col-sm-2 col-form-label">ชื่อพัสดุ</label> -->
            <div class="col-sm-12">
                <input class="form-control" placeholder="ชื่อ, ชื่อเรียก, รายการ" [(ngModel)]="itemsName" [ngModelOptions]="{standalone: true}">
            </div>
            <!-- <div class="col-3">
                <input type="text" class="form-control" [disabled]="disabledItem"
                [(ngModel)]="agent" [ngModelOptions]="{standalone: true}" placeholder="เลข AG">
            </div>
            <div class="col-9">
                <input type="text" class="form-control" [disabled]="disabledItem" 
                [(ngModel)]="token" [ngModelOptions]="{standalone: true}" placeholder="กรุณากรอก Token ก่อน Upload ไฟล์">
            </div> -->
        </div>
        <br>
        <div class="row">
            <div class="col-sm-6">
                <input class="form-control" placeholder="ผู้ถือครอง" [(ngModel)]="itemsHolder" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="col-sm-6">
                <input class="form-control" placeholder="สถานที่ใช้งาน" [(ngModel)]="itemsPlace" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-12">
                <input class="form-control" placeholder="รายละเอียดเพิ่มเติม" [(ngModel)]="itemsDetail" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <!-- <input type="file" class="form-control" id="file" (change)="uploadListener($event)" [disabled]="disabledItem"> -->
        <br>
        <button mat-raised-button style="width: 100%;" disabled="{{btn_disabled}}" (click)="onAddItems()"> Submit </button>
        
    </div>
    <!-- <div class="overlay" *ngIf="progressSpinner">
        <div class="centercontainer">
          <mat-progress-spinner diameter=50 mode="indeterminate" color="accent" value="20">
        </mat-progress-spinner> 
        </div>
    </div> -->
<!-- </div> -->
import { LoginService } from './../../services/login.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  progressSpinner: any;
  userlogin: any;
  password: any;
  name: any;

  constructor(private loginService: LoginService,
    private afAuth: AngularFireAuth,
    private router: Router,) { }

  ngOnInit(): void {
    // this.afAuth.onAuthStateChanged(user => {
    //   console.log(user);
      
    //   if (user == null){
    //     this.router.navigateByUrl('/login')
    //   }
    // })
  }

  async onRegister(){
    this.progressSpinner = true;
    let email = this.userlogin + '@' + this.userlogin + '.com';
    let result = await this.loginService.register(this.name, email, this.password);
    console.log(result);
    if(result){
      this.progressSpinner = false;
      Swal.fire(
        'Succress!',
        'สร้างยูสเซอร์เรียบร้อย',
        'success'
      ).then((result) => {
        // this.router.navigate(['/addstock']);
        // window.location.reload();
      })
    }else{
      this.progressSpinner = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'เกิดข้อผิดพลาด',
      })
    }
  }

}

import { AddDomainComponent } from './add-domain/add-domain.component';
import { DomainComponent } from './domain/domain.component';
import { TimelineComponent } from './stock/timeline/timeline.component';
import { AdditemComponent } from './stock/additem/additem.component';
import { ItemsComponent } from './stock/items/items.component';
import { ListComponent } from './accounts/list/list.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './accounts/register/register.component';
import { LoginComponent } from './login/login.component';
import { CheckuserManualComponent } from './checkuser-manual/checkuser-manual.component';
import { CheckuserExcelComponent } from './checkuser-excel/checkuser-excel.component';
import { DetailComponent } from './detail/detail.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { AddstockComponent } from './addstock/addstock.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactCreateComponent } from './contact-create/contact-create.component';
import { HomeComponent } from './home/home.component';
import { AllComponent } from './dashboards/all/all.component';

const routes: Routes = [
  {path:  "", pathMatch:  "full",redirectTo:  "login"},
  {path: "login", component: LoginComponent}, 
  {path: "home", component: HomeComponent},
  {path: "contact-create", component: ContactCreateComponent},
  {path: "contact-list", component: ContactListComponent},
  {path: "addstock", component: AddstockComponent},
  {path: "schedule", component: ScheduleComponent},
  {path: "detail/:id", component: DetailComponent}, 
  {path: "checkuser-excel", component: CheckuserExcelComponent}, 
  {path: "checkuser-manual", component: CheckuserManualComponent},
  {path: "accounts/register", component: RegisterComponent}, 
  {path: "profile", component: ProfileComponent},
  {path: "accounts/list", component: ListComponent},
  {path: "stock/items", component: ItemsComponent},
  {path: "stock/additem", component: AdditemComponent},
  {path: "stock/timeline/:id", component: TimelineComponent},
  {path: "dashboards/all", component: AllComponent},
  {path: "domain", component: DomainComponent},
  // {path: "domain/items/:id", component: domainItemsComponent},
  {path: "add-domain", component: AddDomainComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { StockService } from '../services/stock.service';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {

  searchitems: any;
  userData: any;
  loginStatus: any;

  itemsData: any;
  tmp: any;

  constructor(private http: HttpClient,
    private afAuth: AngularFireAuth,
    private router: Router,
    private StockService: StockService,) { 

  }

  ngOnInit() {
    this.afAuth.onAuthStateChanged(async user => {
      // console.log('appComponent : ', user);
      if (user == null){
        this.loginStatus = true;
        // this.location.back()
        // this.router.navigate(['/login'])
      }else{
        this.loginStatus = true;
        console.log(this.loginStatus);

        // this.tmp = await this.StockService.getItemsData('enable');
        // console.log('itemsData : ', this.tmp);
        // this.tmp = this.itemsData;
        
      }
    })
  }

}

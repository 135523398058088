import { StockService } from './../../services/stock.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
import * as firebase from 'firebase';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-additem',
  templateUrl: './additem.component.html',
  styleUrls: ['./additem.component.css']
})
export class AdditemComponent implements OnInit {

  loginStatus: any;

  team = 'teamwinwin893';
  teamwinwin893 = 'teamwinwin893';

  itemsName: any;
  itemsHolder: any;
  itemsPlace: any;
  itemsDetail: any;

  btn_disabled = false;

  constructor(private http: HttpClient,
    private afAuth: AngularFireAuth,
    private router: Router,
    private stockService: StockService,
    private afdb: AngularFireDatabase) { }

  ngOnInit(): void {
    this.afAuth.onAuthStateChanged(user => {
      // console.log('appComponent : ', user);
      if (user == null){
        this.loginStatus = false;
        // this.location.back()
        // this.router.navigate(['/login'])
      }else{
        this.loginStatus = true;
        // console.log(this.loginStatus);
      }
    })
  }

  onClickRadio(e){
    console.log('onClickRadio : ', this.team);
    
  }

  onAddItems(){
    console.log(this.team + '-' + this.itemsName + '-' + this.itemsHolder + '-' + this.itemsPlace + '-' + this.itemsDetail);
    let searchWords = this.itemsName + '/' + this.itemsHolder  + '/' + this.itemsPlace + '/' + this.itemsDetail;
    // this.stockService.addItems2Stock(this.team, this.itemsName, this.itemsHolder, this.itemsPlace, this.itemsDetail);
    this.afdb.list('/stock/').push({
      dateCreate: this.getIonicDateTime(),
      team: this.team,
      itemsName: this.itemsName,
      itemsHolder: this.itemsHolder,
      itemsPlace: this.itemsPlace,
      itemsDetail: this.itemsDetail,
      itemsSearch: searchWords, 
      status: 'enable'
    }).then((result: any) => {
      console.log(result);
      this.stockService.addHistory('Add Items Stock', result.key);

      this.afdb.list('/stock/timeline/' + result.key).push({
        dateCreate: this.getIonicDateTime(),
        timelineHolder: this.itemsHolder,
        timelinePlace: this.itemsPlace,
        timelineDetail: this.itemsDetail,
      }).then((dataResult: any) => {
        Swal.fire(
          'Succress!',
          'เพิ่มรายการสินค้าเรียบร้อย',
          'success'
        ).then((result) => {
          this.itemsName = '';
          this.itemsHolder = '';
          this.itemsPlace = '';
          this.itemsDetail = '';
        })
      });
    });
  }

  getIonicDateTime(): string {
    let date: Date = new Date();
    let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return ionicDate.toISOString();
  }

}

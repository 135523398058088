
<div class="jumbotron" style="background-color: #fff; height: calc(95vh);"> 
  <div class="row">
    <div class="col-3">
      <div class="card text-center text-white mb-4" 
      style="border-radius: 30px;background-color: #343a40;" 
      (click)="gotoPreviousDay()">
          <div class="card-body">
              <<
          </div>
      </div>
    </div>
    <div class="col-6 text-center">
      <h2>{{dateDay_Word}} {{dateMonth_Word}}</h2>
    </div>
    <div class="col-3">
      <!-- <div class="card text-center text-white mb-4" 
      style="border-radius: 30px;background-color: #343a40;" 
      (click)="gotoNextDay(now)">
          <div class="card-body">
              >>
          </div>
      </div> -->
    </div>
  </div>
  <!---->
  <div class="row">
    <div class="col-xl-3 col-md-6 col-sm-6 col-12" *ngFor="let item of stockList">
      <div class="card text-white mb-4" (click)="gotoStockDetail(item)"
       style="border-radius: 30px;" [ngClass]="[item.bgcolor]">
        <div class="card-body">
          <div class="row">
            <div class="col-12">{{item.stockName}}</div>
            <h2 class="col-8" id={{item.countcss}}>$ {{item.total}} </h2>
            <!-- <h4 class="col-4" *ngIf="item.percent <= 0"    {{item.total | number}}
            style="color: white;" id={{item.countpersentcss}}>{{item.percent | number:'1.0-0'}} %</h4>
            <h4 class="col-4" *ngIf="item.percent > 0" 
            style="color: greenyellow;" id={{item.countpersentcss}}>{{item.percent | number:'1.0-0'}} %</h4> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <div class="card text-center mb-4" (click)="onLogout()"
        style="border-radius: 20px;background-color: #ffffff;">
        <div class="card-body">
          <h3>รวม : {{allTotal | number}}</h3>
          <!-- <h3>รวม : {{allTotal}}</h3> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="progressSpinner">
  <div class="center">
    <mat-progress-spinner diameter=50 mode="indeterminate" color="accent" value="20">
  </mat-progress-spinner> 
  </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { ContactCreateComponent } from './contact-create/contact-create.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { FormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment.prod';
import { AddstockComponent } from './addstock/addstock.component';
// import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScheduleComponent } from './schedule/schedule.component';
import { DetailComponent } from './detail/detail.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatSliderModule } from '@angular/material/slider';
import { HttpClientModule } from '@angular/common/http';

import { SideNavComponent } from './side-nav/side-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CheckuserExcelComponent } from './checkuser-excel/checkuser-excel.component'
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { CheckuserManualComponent } from './checkuser-manual/checkuser-manual.component';
import { RegisterComponent } from './accounts/register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { ListComponent } from './accounts/list/list.component';
import { ItemsComponent } from './stock/items/items.component';
import { AdditemComponent } from './stock/additem/additem.component';
import { TimelineComponent } from './stock/timeline/timeline.component';

import { ChartsModule } from 'ng2-charts';
import { DomainComponent } from './domain/domain.component';
import { AddDomainComponent } from './add-domain/add-domain.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactCreateComponent,
    ContactListComponent,
    HeaderComponent,
    FooterComponent,
    AddstockComponent,
    ScheduleComponent,
    DetailComponent,
    LoginComponent,
    SideNavComponent,
    CheckuserExcelComponent,
    CheckuserManualComponent,
    RegisterComponent,
    ProfileComponent,
    ListComponent,
    ItemsComponent,
    AdditemComponent,
    TimelineComponent,
    DomainComponent,
    AddDomainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    NgxMatFileInputModule,
    ChartsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'example';
  // userData: any;

  loginStatus: any;
  
  constructor(private afAuth: AngularFireAuth,
    private router: Router,){
    console.log(this.title);
  }

  ngOnInit(): void {
    // this.afAuth.onAuthStateChanged(user => {
    //   console.log('appComponent : ', user);
    //   if (user == null){
    //     // this.loginStatus = false;
    //     // this.router.navigateByUrl('/login');
    //     // this.router.navigate(['/login']);
    //     this.router.navigate(['/login'], { replaceUrl: true })
    //   }else{
    //     this.loginStatus = true;
    //     console.log(this.loginStatus);
    //     this.router.navigate(['/profile']);
    //   }
    // })
  }
  
}

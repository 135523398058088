import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

// import { saveAs } from 'file-saver/FileSaver';

@Component({
  selector: 'app-checkuser-excel',
  templateUrl: './checkuser-excel.component.html',
  styleUrls: ['./checkuser-excel.component.css']
})
export class CheckuserExcelComponent implements OnInit {

  fileToUpload: File = null;
  DatafileUpload: any;
  lines = [];
  btn_disabled = true;
  progressSpinner = false;

  loginStatus: any;
  agent: any;
  // token = '2bd83ee08660d5c03245f4e62cc16e34';
  token: any;
  url = '/api/checkuser1611.php';
  resultArray: any;
  webapi: any;
  weblottovip = 'weblottovip';
  webruay = 'webruay';

  disabledItem = true;
  showPopup = false; // Flag to control popup visibility
  pwdunlock: any;

  constructor(private http: HttpClient,
    private afAuth: AngularFireAuth,
    private router: Router,) { }

  ngOnInit(): void {
    this.loginStatus = true;
    // this.afAuth.onAuthStateChanged(user => {
    //   // console.log('appComponent : ', user);
    //   if (user == null){
    //     this.loginStatus = true;
    //     // this.location.back()
    //     // this.router.navigate(['/login'])
    //   }else{
    //     this.loginStatus = true;
    //     console.log(this.loginStatus);
    //   }
    // })
  }

  onClickRadio(e){ 
    // console.log(this.disabledItem);
    // this.disabledItem = false; 
  }

  onChangePWD(e){
    console.log(this.pwdunlock.length);
    if(this.pwdunlock == '893893'){
      this.disabledItem = false;
    }
  }

  uploadListener2(files: FileList) {
    this.fileToUpload = files.item(0);
    // this.DatafileUpload = files.item(0);
    // for (let i = 0; i < 1; i++) {
    //   this.DatafileUpload[i].forEach(element => {
    //     console.log('element : ', element);
    //   });
      
    // }
    
    // console.log(this.a);
    
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async uploadListener_ORG(fileInput: any){
    //read file from input
    // this.fileReaded = fileInput.target.files[0];
    this.progressSpinner = true;

    let reader: FileReader = new FileReader();
    reader.readAsText(fileInput.target.files[0]);
    
    reader.onload = (e) => {
      let csv: string = reader.result.toString();
      let allTextLines = csv.split(/\r|\n|\r/);
      let headers = allTextLines[0].split(',');
      // let lines = [];
      
      let countTran = 0
      this.lines.push(['uid', 'userLogin', 'tel_no', 'Wallet', 'Wallet_AF', 'ชื่อ_นามสกุล', 'จำนวนฝาก', 'ฝากไม่ผ่าน', 'จำนวนถอน', 'วันที่ฝากล่าสุด', 'สมัครวันที่', 'ยอดฝากรวม', 'ยอดถอนรวม', 'ยอดAF', 'จำนวนAF', 'Last_Login']);
      for (let i = 1; i < allTextLines.length; i++) {
        // this.progressSpinner = true;

        // split content based on comma
        let data = allTextLines[i].split(',');
        console.log('data[0] : ', data[0]);
        countTran++;

        let urlApi = this.url + '?token=' + this.token + '&tel=' + data[0] + '&agent=' + this.agent + '&web=' + this.webapi;
        // console.log(urlApi);
        let tarr = [];
        setTimeout(async () => {
          this.resultArray = await this.getDataUser(urlApi);
          // console.log('result : ', this.resultArray);
          // this.resultArray.forEach(element => {
            
          // });
          for (let j = 0; j < this.resultArray.length; j++) {
            // tarr.push(this.resultArray[j]);
            this.lines.push([this.resultArray[j].uid, this.resultArray[j].username, this.resultArray[j].tel_no, this.resultArray[j].wallet, this.resultArray[j].wallet_af, this.resultArray[j].real_name, this.resultArray[j].deposit_count, this.resultArray[j].deposit_fail_count, this.resultArray[j].withdraw_count, this.resultArray[j].last_deposit, this.resultArray[j].register_date, this.resultArray[j].deposit_total, this.resultArray[j].withdraw_total, this.resultArray[j].af_money_total, this.resultArray[j].af_users_total, this.resultArray[j].last_login_dt]);
          }
        }, 1000)
        
        // this.lines.push(tarr);

        // if (data.length === headers.length) {
        //   let tarr = [];
        //   for (let j = 0; j < headers.length; j++) {
        //     tarr.push(data[j]);
        //     console.log(data[0]);
        //   }
          
        //   // log each row to see output 
        //   // console.log(tarr);
        //   this.lines.push(tarr);
        // }

        if(i == (allTextLines.length-1)){this.btn_disabled = false;}
      }
      // all rows in the csv file 
      console.log(">>>>>>>>>>>>>>>>>", this.lines);
      // this.progressSpinner = false;
      // (async () => { 
      //   // Do something before delay
      //   console.log('before delay')
      //   this.progressSpinner = true;
  
      //   await this.delay(1000);
  
      //   // Do something after
      //   console.log('after delay')
      //   this.progressSpinner = false;
      // })();
    } 
  }

  async uploadListener(fileInput: any){
    if(this.pwdunlock != '893893'){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Password Unlock Incorrect',
        // footer: '<a href>Why do I have this issue?</a>'
      })
    }else{
    //read file from input
    // this.fileReaded = fileInput.target.files[0];
    this.showPopup = true;
    this.progressSpinner = true;

    let reader: FileReader = new FileReader();
    reader.readAsText(fileInput.target.files[0]);
    
    reader.onload = (e) => {
      let csv: string = reader.result.toString();
      let allTextLines = csv.split(/\r|\n|\r/);
      let headers = allTextLines[0].split(',');
      // let lines = [];

      let countTran = 0
      this.lines.push(['uid', 'userLogin', 'tel_no', 'Wallet', 'Wallet_AF', 'ชื่อ_นามสกุล', 'จำนวนฝาก', 'ฝากไม่ผ่าน', 'จำนวนถอน', 'วันที่ฝากล่าสุด', 'สมัครวันที่', 'ยอดฝากรวม', 'ยอดถอนรวม', 'ยอดAF', 'จำนวนAF', 'Last_Login']);
      
      (async () => { 
        // Do something before delay
        console.log('before delay')
        this.progressSpinner = true;
        // let number = '';
        for (let i = 1; i < allTextLines.length-1; i++) {
          // this.progressSpinner = true;
  
          // split content based on comma
          let data = allTextLines[i].split(',');
          if(data[0].length < 10){data[0] = '0' + data[0];}
          
          if(data[0] != '0'){
            // แก้ไขเรื่องเบอร์ซ้ำ
            // number = data[0];
            console.log(i, data[0]);
            countTran++;

            let urlApi = this.url + '?token=' + this.token + '&tel=' + data[0] + '&agent=' + this.agent + '&web=' + this.webapi;
            // console.log(urlApi);
            let tarr = [];
            
            this.resultArray = await this.getDataUser(urlApi);
            // console.log('result : ', this.resultArray);

            await this.delay(1000);
            
            if(this.resultArray != null){
              for (let j = 0; j < this.resultArray.length; j++) {
                // tarr.push(this.resultArray[j]);
                this.lines.push([this.resultArray[j].uid, this.resultArray[j].username, this.resultArray[j].tel_no, this.resultArray[j].wallet, this.resultArray[j].wallet_af, this.resultArray[j].real_name, this.resultArray[j].deposit_count, this.resultArray[j].deposit_fail_count, this.resultArray[j].withdraw_count, this.resultArray[j].last_deposit, this.resultArray[j].register_date, this.resultArray[j].deposit_total, this.resultArray[j].withdraw_total, this.resultArray[j].af_money_total, this.resultArray[j].af_users_total, this.resultArray[j].last_login_dt]);
              }

              if(i == (allTextLines.length-1)){this.btn_disabled = false;}
              this.btn_disabled = false;
              this.progressSpinner = false;
            }else{
              this.lines.push([data[0], 'Null Username', 'Null TelNo', 'Null wallet', 'Null walletAF', 'Null real_name', 'Null deposit_count', 'Null deposit_fail_count', 'Null withdraw_count', 'Null withdraw_count']);
            }

          }
        }
  
        // Do something after
        console.log('after delay')
        this.showPopup = false;
        this.progressSpinner = false;
      })();
      
      // all rows in the csv file 
      console.log(">>>>>>>>>>>>>>>>>", this.lines);
    } 
  }
  }//if check password unlock

  onCilckCheck(){
    console.log(this.webapi);
  }

  downloadFile(data: any) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  getDataUser(url): Promise<any> {
    return new Promise(resolve => {
      this.http.get(url).subscribe({
        next: data => {
            // console.log(data);
            resolve(data);
        },
        error: error => {
            this.showPopup = false;
            console.error('There was an error!', error);
        }
      })
    })
  }

  // getDataUser2(url){
  //   this.http.get(url).subscribe((data: any)=>{
  //     this.resultArray = data;
  //     if(this.resultArray.length != null){
  //       for (let j = 0; j < this.resultArray.length; j++) {
  //         this.lines.push([this.resultArray[j].uid, this.resultArray[j].username, this.resultArray[j].tel_no, this.resultArray[j].wallet, this.resultArray[j].wallet_af, this.resultArray[j].real_name, this.resultArray[j].deposit_count, this.resultArray[j].deposit_fail_count, this.resultArray[j].withdraw_count, this.resultArray[j].last_deposit, this.resultArray[j].register_date, this.resultArray[j].deposit_total, this.resultArray[j].withdraw_total, this.resultArray[j].af_money_total, this.resultArray[j].af_users_total, this.resultArray[j].last_login_dt]);
  //       }

  //       this.btn_disabled = false;
  //       this.progressSpinner = false;
  //     }else{
  //       this.lines.push([data[0], 'Null Username', 'Null TelNo', 'Null wallet', 'Null walletAF', 'Null real_name', 'Null deposit_count', 'Null deposit_fail_count', 'Null withdraw_count', 'Null withdraw_count']);
  //     }
  //   })
  // }

}

import { StockService } from './../../services/stock.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {

  dataTimeline: any;
  holder: any;
  detail: any;
  place: any;
  itemsName: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private StockService: StockService,
    private afdb: AngularFireDatabase) { 

    if (this.router.getCurrentNavigation().extras.state) {
      this.itemsName = this.router.getCurrentNavigation().extras.state.itemsName;
    }
  }

  async ngOnInit() {
    console.log('ngOnInit :');
    let itemsKey = this.route.snapshot.params.id;
    console.log('itemsKey :', itemsKey);
    // this.dataTimeline = await this.StockService.getTimeline(itemsKey);
    // console.log('dataTimeline : ', this.dataTimeline);
    this.StockService.getTimeline(itemsKey).snapshotChanges().subscribe((data: any)=>{
      if(data != null)
        // this.dataTimeline = data.map(c => ({ key: c.key, ...c.payload.val() }))
        this.dataTimeline = data.map(c => ({ key: c.key, ...c.payload.val() })).sort((a, b) => {
          if (a.dateCreate > b.dateCreate) {return -1;}
          if (a.dateCreate < b.dateCreate) {return 1;}
          return 0;
        })
        console.log('dataTimeline : ', this.dataTimeline);
    })

  }

  onAddTimeline(){
    console.log(this.holder, this.detail + ' ' + this.place);
    let itemsKey = this.route.snapshot.params.id;
    this.afdb.list('/stock/timeline/' + itemsKey).push({
      dateCreate: this.getIonicDateTime(),
      timelineHolder: this.holder,
      timelinePlace: this.place,
      timelineDetail: this.detail,
    }).then((dataResult: any) => {
      this.StockService.addHistory('Add TimeLine Items', dataResult.key);
      this.afdb.object('/stock/' + itemsKey).update({
        itemsHolder: this.holder,
        itemsPlace: this.place,
        itemsDetail: this.detail,
      }).then(()=>{
        this.holder = '';
        this.place = '';
        this.detail = '';
      })
    });
  }

  getIonicDateTime(): string {
    let date: Date = new Date();
    let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return ionicDate.toISOString();
  }

  onDisable(){

  }

}

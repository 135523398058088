import { DataService } from './../../services/data.service';
import { Component, OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  loginStatus: any;
  userList: any;

  constructor(private dataService: DataService,
    private afAuth: AngularFireAuth,
    private router: Router,
    ) { }

  async ngOnInit(){
    this.afAuth.onAuthStateChanged(user => {
      // console.log('appComponent : ', user);
      if (user == null){
        this.loginStatus = false;
        this.router.navigate(['/login'])
      }else{
        this.loginStatus = true;
        console.log(this.loginStatus);

        this.getDatauserList();
      }
    })
    // this.dataService.getDatauserList().then((data: any)=>{
    //   if(data)
    //     console.log(data.payload.val());
    // })
  }

  async getDatauserList(){
    this.userList = await this.dataService.getDatauserList();
    console.log('userList :', this.userList);
  }

  onClickuser(items){
    // console.log(items);
    // this.router.navigate(['/accounts/' + items.key])
  }

}

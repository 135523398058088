<div class="jumbotron" style="background-color: #fff; height: calc(95vh);"> 
    <div class="row">
      <div class="col-12">
        <div class="card text-white mb-4 gray-color" style="border-radius: 30px;" >
          <div class="card-body">
            <div class="row">
              <h3 class="col-4">เฉลี่ย</h3>
              <!-- <h2 class="col-8" id={{item.countcss}}>$ {{item.total}} </h2> -->
              <h2 class="col-8 text-right">{{ average | number }} / วัน</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    
      <div class="card-header">
          Area Chart Example
      </div>
      <div class="card-body">
        <canvas #myAreaChart></canvas>
      </div>
    
</div>
<!-- <canvas id="myChart" width="400" height="400"></canvas>
<script>
var ctx = document.getElementById('myChart').getContext('2d');
var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
});
</script> -->
<!-- <p>detail works!</p> -->

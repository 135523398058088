<div class="container" style="margin-top: 70px;">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of contacts">
  
          <td>{{ contact.id }}</td>
          <td> {{ contact.name }}</td>
          <td> {{ contact.email }}</td>
          <td>
            <button class="btn btn-primary" (click)="selectContact(contact)"> Show details</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="card text-center" *ngIf="selectedContact">
        <div class="card-header">
          # {{selectedContact.id}}
        </div>
        <div class="card-block">
          <h4 class="card-title">{{selectedContact.name}}</h4>
          <p class="card-text">
            {{selectedContact.description}}
          </p>    
        </div>
  
      </div>
  </div>
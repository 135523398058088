import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { Router } from '@angular/router';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private afdb: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private router: Router,) { }

  // getUserlogin(userLogin, password): Promise<any> {
  //   return new Promise(resolve => {
  //     this.afdb.list('/huay/account/' , ref => ref.orderByChild('userlogin').equalTo(userLogin)).snapshotChanges().subscribe((data: any)=>{
  //       // resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
  //       let userData = data.map(c => ({ key: c.key, ...c.payload.val() }));
  //       // console.log(userData);
  //       if(password == userData[0].password){
  //         resolve(true);
  //       }else{
  //         resolve(false);
  //       }
  //     });
  //   })
  // }

  login(email, password) {
    this.afAuth.signInWithEmailAndPassword(email, password).then((res) => {
      // this.router.navigateByUrl('/tabs/tab1');
      this.router.navigateByUrl('/profile');
      console.log("res : ", res);
    }).catch(err => {
      console.log("err : ", err);
      // this.loadingProvider.showToast(err.message);
    })
  }

  register(name, email, password): Promise<any> {
    return new Promise(resolve => {
      this.afAuth.createUserWithEmailAndPassword(email, password).then((res) => {
        let user: any = firebase.auth().currentUser;
        // this.createNewUser(user.uid, name, user.email, password, 'enable');
        let datecreated = this.getIonicDateTime();
        this.afdb.object('/huay/account/' + user.uid).update({
          datecreated,
          name,
          email,
          password,
          status : 'enable',
          type : 'newuser'
        }).then(() => {
          // this.router.navigateByUrl('/')
          resolve(true);
        });
      }).catch(err => {
        console.log(err);
        resolve(false);
      })
    });
  }

  createNewUser(user_uid, name, email, password, status){
    let datecreated = this.getIonicDateTime();
    this.afdb.object('/huay/account/' + user_uid).update({
      datecreated,
      name,
      email,
      password,
      status,
    }).then(() => {
      // this.router.navigateByUrl('/')
    });
  }

  getDatauser(uid): Promise<any> {
    return new Promise(resolve => {
      this.afdb.object('/huay/account/' + uid).snapshotChanges().subscribe((data: any)=>{
        // resolve(data.map(c => ({ key: c.key, ...c.payload.val() })));
        // let userData = data.map(c => ({ key: c.key, ...c.payload.val() }));
        resolve(data.payload.val());
      });
    })
  }

  getIonicDateTime(): string {
    let date: Date = new Date();
    let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return ionicDate.toISOString();
  }

  logout() {
    this.afAuth.signOut().then(()=>{
      this.router.navigateByUrl('/')
      window.location.reload();
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-add-domain',
  templateUrl: './add-domain.component.html',
  styleUrls: ['./add-domain.component.css']
})
export class AddDomainComponent implements OnInit {

  //Domain&Host
  domainHost: any; directadmin: any; userDirectadmin: any; passDirectadmin: any;
  //FTP
  hostFTP: any; userFTP: any; passFTP: any;
  //WordPress
  userWP: any; passWP: any;
  //Provider
  userRegis: any; passRegis: any; expriredRegis: any; hostRegis: any;
  //Cloudflare
  emailCloudflare: any; passCloudflare: any;
  //Note
  noteDomain: any;

  constructor(private afdb: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private router: Router,) { }

  ngOnInit(): void {
  }

  onAddDomain(){
    console.log(this.passCloudflare, this.noteDomain);
    this.afdb.list('/domain/').push({
      dateCreate: this.getIonicDateTime(),
      domainHost: this.domainHost,
      directadmin: this.directadmin,
      userDirectadmin: this.userDirectadmin,
      passDirectadmin: this.passDirectadmin,
      hostFTP: this.hostFTP,
      userFTP: this.userFTP,
      passFTP: this.passFTP,
      userWP: this.userWP,
      passWP: this.passWP,
      userRegis: this.userRegis,
      passRegis: this.passRegis,
      expriredRegis: this.expriredRegis,
      hostRegis: this.hostRegis,
      emailCloudflare: this.emailCloudflare,
      passCloudflare: this.passCloudflare,
      noteDomain: this.noteDomain,
      status: 'enable'
    }).then((result: any) => {
      console.log(result);
      Swal.fire(
        'Succress!',
        'สร้างยูสเซอร์เรียบร้อย',
        'success'
      ).then((result) => {
        this.router.navigate(['/domain']);
        // window.location.reload();
      })
    });
  }

  getIonicDateTime(): string {
    let date: Date = new Date();
    let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return ionicDate.toISOString();
  }

}
